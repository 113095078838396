import React from "react";
// import styled from "styled-components";
import Div100vh from "react-div-100vh";

// Components
// import { Header } from "./header";
// import { Footer } from "./footer";

// const Main = styled.main`
//   flex: 1;
// `;

export const App = ({ location, data, children }) => {
  return (
    <>
      {/* <Header location={location} /> */}
      {/* <Div100vh> */}
      {children}
      {/* </Div100vh> */}
      {/* <Footer location={location} /> */}
    </>
  );
};
