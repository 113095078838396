exports.linkResolver = doc => {
  // URL for a category type
  // if (doc.type === "artist") {
  //   return `/${doc.uid}/`;
  // }

  // // URL for a product type
  // if (doc.type === "product") {
  //   console.log(doc);
  //   return `/${doc.uid}/`;
  //   // return `/${doc.data.artist.uid}/${doc.data.artwork.handle}/`;
  // }

  // // URL for a page type
  // if (doc.type === "artists") {
  //   return `/`;
  // }

  // Backup for all other types
  return `/${doc.uid}/`;
};
