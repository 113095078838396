import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Logo } from "../components/icons/logo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  & .top-section {
    position: relative;
    height: 100svh;
    width: 100vw;

    & .logo-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      z-index: 1;

      & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & .video-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      z-index: 0;

      & video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        object-fit: cover;

        width: 100vw;
        height: 100svh;
      }
    }

    & .down-arrow-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      z-index: 10;

      & button {
        cursor: pointer;
      }
    }
  }

  & .lower-section {
    min-height: 100svh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 20px;

    & .text-container {
      color: #7b8b7c;
      text-align: center;
      max-width: 940px;
      margin: 0 auto;

      padding: 20px;
    }
  }
`;

// @keyframes float {
// 	0% {
// 		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
// 		transform: translatey(0px);
// 	}
// 	50% {
// 		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
// 		transform: translatey(-20px);
// 	}
// 	100% {
// 		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
// 		transform: translatey(0px);
// 	}
// }
// 	animation: float 6s ease-in-out infinite;

const Index = ({ data }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 700px)");

  setTimeout(() => {
    // Code to run after 20 seconds

    if (typeof window !== `undefined`) {
      document.getElementById("lower").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, 20000);

  // res.cloudinary.com/kieranstartup/video/upload/ac_none,c_crop,g_center,h_1080,w_700/v1712957096/kellie-stupart-video.mp4

  // https:

  return (
    <Page>
      <div className="top-section">
        <div className="logo-container">
          <Logo />
        </div>

        <div className="video-container">
          {/* {isDesktop ? ( */}
          <video
            src={`https://video.gumlet.io/66ddfb6f21f1e264a3e529f5/66e853246624f47444429e02/download.mp4`}
            poster={`https://video.gumlet.io/66ddfb6f21f1e264a3e529f5/66e853246624f47444429e02/thumbnail-1-0.png?v=1726501723227`}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          />
          {/* ) : (
            <video
              src={`https://res.cloudinary.com/kieranstartup/video/upload/ac_none,c_crop,g_center,h_1080,w_700/v1712957096/kellie-stupart-video.mp4`}
              poster={`https://res.cloudinary.com/kieranstartup/video/upload/ac_none,c_crop,g_center,h_1080,w_700/v1712957096/kellie-stupart-video.jpg`}
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
            />
          )} */}
        </div>

        <div className="down-arrow-container">
          <button
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                document.getElementById("lower").scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
            }}
          >
            <svg
              width="42"
              height="23"
              viewBox="0 0 42 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L21 21.5L41 1.5"
                stroke="#EBE9E1"
                stroke-width="3"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="lower-section" id="lower">
        <div className="text-container">
          <h1>
            Kellie Stupart is a Garden Designer who lives on the East coast of
            Scotland.
          </h1>

          <p>
            You can find her at{" "}
            <a href="mailto:studio@kelliestupart.com">
              studio@kelliestupart.com
            </a>
          </p>
        </div>
      </div>
    </Page>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHome {
      data {
        text {
          html
        }
      }
    }
  }
`;
